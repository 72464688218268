import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/public.css'
import { Button, Select,Option,Table,TableColumn,Input,Pagination,
  Carousel,
  CarouselItem, } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(Carousel)
Vue.use(CarouselItem)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
