<template>
  <div class="qa">
    <NavHeader class="nav" :mode="mode"></NavHeader>
    <section class="one">
      <div class="bg">
        <img src="@/assets/images/qa-bg.png" alt=""/>
      </div>
      <div class="flex-box">
        <div class="container">
          <div class="top">
            <div class="name">
              {{ curQa.name }}
            </div>
            <div class="desc">
              {{ curQa.introduce }}
            </div>
          </div>
          <div class="middle">
            <div class="left" v-html="curQa.content">
            </div>
            <div class="right" v-if="curQa.pic">
              <div class="pic-bg"></div>
              <div class="pic-box">
                <img :src="curQa.pic" alt=""/>
              </div>
            </div>
          </div>
          <div class="bottom">
            <img src="@/assets/images/qa-btn-bg.png" alt=""/>
            <div class="prev">
              <div class="icon" @click="handlePrev()" :style="prevQa.id ? '' : 'cursor: not-allowed;'">
                <img src="@/assets/images/qa-prev-icon.png" alt=""/>
              </div>
              <div class="divide"></div>
              <div class="name">{{ prevQa.name }}</div>
            </div>
            <div class="next">
              <div class="name">{{ nextQa.name }}</div>
              <div class="divide"></div>
              <div class="icon" @click="handleNext()" :style="nextQa.id ? '' : 'cursor: not-allowed;'">
                <img src="@/assets/images/qa-next-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import NavHeader from './NavHeader.vue'
import Footer from './Footer.vue'
import { QA_LIST } from "@/api"

export default {
  name: 'Qa',
  components: {
    NavHeader,
    Footer
  },
  data(){
    return {
      mode: "our",
      curId: "",
      qaList: [],
      curQa: {},
      prevQa: {},
      nextQa: {},
    };
  },
  methods:{
    handlePrev(){
      if(!this.prevQa.id){
        return;
      }
      this.parseData(this.prevQa.id);
    },
    handleNext(){
      if(!this.nextQa.id){
        return;
      }
      this.parseData(this.nextQa.id);
    },
    async queryQaList(){
      this.qaList = this.$store.state.qaList;
      if(this.qaList.length == 0){
        let res = await QA_LIST();
        if(res.code != 1){
          return;
        }
        for (let i = 0; i < res.data.length; i++) {
          this.qaList.push(res.data[i]);
        }
      }
      this.parseData(this.curId);
    },
    //解析数据
    parseData(id){
      for (let i = 0; i < this.qaList.length; i++) {
        if(this.qaList[i].id == id){
          this.curQa = this.qaList[i];
          this.prevQa = i - 1 >= 0 ? this.qaList[i-1] : {};
          this.nextQa = i + 1 < this.qaList.length ? this.qaList[i+1] : {};
          break;
        }
      }
      document.body.scrollIntoView();
    },
  },
  mounted(){
    this.curId = this.$route.params.index;
    this.queryQaList();
  }
}
</script>
<style scoped lang="less">
.qa{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.nav{
  border-bottom: 1px solid rgba(0,0,0,0.08);
}

.one{
  position: relative;
  min-height: 1373px;
  .flex-box{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .container{
    margin: 96px 0 171px 0;
    max-width: 1280px;
    .top{
      height: 84px;
      border-bottom: 1px solid rgba(0,0,0,0.08);
      text-align: left;
      line-height: 32px;
      .name{
        font-size: 20px;
      }
      .desc{
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .middle{
      display: flex;
      justify-content: space-between;
      .left{
        font-size: 14px;
        line-height: 25px;
        white-space: pre-line;
        text-align: left;
        margin-top: 37px;
        // max-width: 852px;
        min-height: 815px;
        max-height: 950px;
        overflow: hidden;
        span{
          color: #45a0ea;
        }
      }
      .right{
        margin-top: 54px;
        position: relative;
        margin-left: 117px;
        .pic-bg{
          width: 311px;
          height: 437px;
          background-color: #c8e6ff;
        }
        .pic-box{
          width: 311px;
          height: 437px;
          position: absolute;
          top: -16px;
          left: -16px;
          background-color: #ededed;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
      }
    }
    .bottom{
      margin-top: 95px;
      position: relative;
      .prev{
        position: absolute;
        top: 0;
        left: 30px;
        display: flex;
        justify-content: space-between;
        .icon{
          margin: auto;
          line-height:0;
          cursor: pointer;
        }
        .divide{
          border-left: 1px solid #45a0ea;
          margin: 9px 44px 9px 14px;
          height: 23px;
        }
        .name{
          margin: auto;
          color: #45a0ea;
        }
      }
      .next{
        position: absolute;
        top: 0;
        right: 30px;
        display: flex;
        justify-content: space-between;
        .icon{
          margin: auto;
          line-height:0;
          cursor: pointer;
        }
        .divide{
          border-right: 1px solid #c8e6ff;
          margin: 9px 14px 9px 44px;
          height: 23px;
        }
        .name{
          margin: auto;
          color: #c8e6ff;
        }
      }
    }
  }
}

</style>