<template>
  <div class="home">
    <NavHeader class="nav" :mode="mode" />
    <section class="one">
      <div class="bg">
        <div class="bg-video">
          <video ref="video" src="/video/video.mp4" controls autoplay loop muted v-show="canplay"></video>
        </div>
        <div class="bg-txt">
          <img src="@/assets/images/bg-txt.png" alt="">
        </div>
        <div class="bg-tips">
          <img src="@/assets/images/bg-tips.png" alt="">
        </div>
      </div>
    </section>
    <section class="two">
      <div class="bg">
        <div class="content">
          <div class="title">
            <img src="@/assets/images/about-title.png">
          </div>
          <div class="txt">
            <p>创乐汇成立于2016年10月，以科学精神快速进行技术积累，在研发壁垒极高的实操足球游戏</p>
            <p>领域实现技术追赶，已经成为国内领先的运动竞技游戏开发商。</p>
            <p>&nbsp;</p>
            <p>公司的首款竞技实操手游《绿茵之巅》已在全球发行，由前巴西巨星卡洛斯代言，</p>
            <p>上线一年在全球即拥有500多万用户。</p>
          </div>
          <div class="divide"></div>
          <div class="more">
            <router-link to="/our">
              <img src="@/assets/images/about-more.png">
            </router-link>
          </div>
        </div>
        <div class="pic">
          <img src="@/assets/images/about-pic.png">
        </div>
      </div>
    </section>
    <section class="three">
      <div class="bg">
        <img class="left" src="@/assets/images/event-bg-left.png"/>
        <img class="bottom" src="@/assets/images/event-bg-bottom.png"/>
      </div>
      <div class="content">
        <div class="title">
          <img src="@/assets/images/event-title.png">
        </div>
        <div class="grid">
          <ul>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/now.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/now-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                新产品 《Soccer League》（代号）火热研发中……
              </span>
            </li>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/202100.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/202100-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                公司进入高速发展阶段，规模迅速扩大
              </span>
            </li>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/201912.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/201912-pic2.png" alt="">
                </span>
              </span>
              <span class="right">
                上线国服，实时对战，逼真还原！
              </span>
            </li>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/201912.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/201912-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                上线南美并登顶；前巴西巨星——黄金左脚卡洛斯代言游戏
              </span>
            </li>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/201900.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/201900-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                项目上线，首发东南亚
              </span>
            </li>
            <li>
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/201806.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/201806-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                网易投资入股并独家代理《绿茵之巅》
              </span>
            </li>
            <li class="last">
              <span class="left">
                <span class="time">
                  <img src="@/assets/images/201610.png" alt="">
                </span>
                <span class="divide">
                </span>
                <span class="pic">
                  <img src="@/assets/images/201610-pic.png" alt="">
                </span>
              </span>
              <span class="right">
                公司成立 专注于研发体育竞技游戏。
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>

import NavHeader from './NavHeader.vue'
import Footer from './Footer.vue'

export default {
  name: 'Home',
  components: {
    NavHeader,
    Footer
  },
  data(){
    return {
      mode: "home",
      canplay: false,
    };
  },
  mounted(){
    this.$refs.video.oncanplay = () =>{
      this.canplay = true;
    }
  }
  
}
</script>
<style scoped lang="less">
.home{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.nav{
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(55, 47, 157, 0.24);
}

.one {
  height: 100vh;
  max-height: 1080px;
  // min-height: 710px;
  position: relative;
  z-index: 1;
  .bg {
    width: 100%;
    height: 100%;
    background: url("../assets/images/bg-home.png") no-repeat top center;
    /* background-size: cover; */
    position: relative;
  }
  .bg-video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .bg-txt{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img{
      position: absolute;
      top: 25.37%;
      left: 98px;
    }
  }
  .bg-tips{
    position: absolute;
    bottom: 4.6%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.two {
  height: 713px;
  .bg {
    width: 100%;
    height: 100%;
    background: url("../assets/images/about-bg.png") no-repeat top center;
    /* background-size: cover; */
    position: relative;
    margin-top: -45px;
    display: flex;
    justify-content: space-between;
  }
  .content{
    margin: 154px 0 46px 10.88%;
    text-align: left;
    display: flex;
    flex-direction: column;
    .txt{
      margin: 56px 0 0 0;
      line-height: 30px;
      white-space: pre-line;
    }
    .divide{
      height: 111px;
      border-left: 1px solid #45a0ea;
      margin: 42px 0;
    }
    .more{
      cursor: pointer;
    }
  }
  .pic{
    margin: 153px 10.88% 0 0;
  }
}

.three{
  background-color: #f3f3f3;
  position: relative;
  .bg{
    position: relative;
    text-align: left;
    .left{
      margin: 109px 0 11px 0;
    }
    .bottom{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .content{
    position: absolute;
    top: 109px;
    left: 212px;
    right: 207px;
    text-align: left;
  }
  .grid{
    li{
      height: 128px;
      line-height: 128px;
      border-bottom: 1px solid #6d6d6d;
      display: flex;
      justify-content: space-between;
    }
    .last{
      border-bottom: none;
    }
    .time{
      margin: 47px 32px;
      img{
        vertical-align: middle;
        text-align: center;
        width: 76px;
      }
    }
    .divide{
      height: 61px;
      line-height: 61px;
      border-left: 1px solid #6d6d6d;
      display: inline-block;
      vertical-align: middle;
    }
    .pic{
      margin: 47px 32px;
      img{
        vertical-align: middle;
      }
    }
    .right{
      margin-right: 30px;
      font-size: 16px;
      flex: 1 0;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>