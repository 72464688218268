<template>
  <div :class="['header', isHome ? '' : 'normal']">
    <span :class="['logo', isHome ? 'home' : 'normal']">
      <img v-if="isHome" src="@/assets/images/logo-home.png" />
      <img v-else src="@/assets/images/logo-normal.png" />
    </span>
    <span class="space"></span>
    <span :class="['nav', isHome ? 'home' : 'normal']">
      <ul class="menu">
        <li :class="['item', isActive('home') ? 'active' : '']">
          <router-link to="/">首页</router-link>
        </li>
        <li :class="['item', isActive('game') ? 'active' : '']">
          <router-link to="/game">游戏产品</router-link>
        </li>
        <li :class="['item', isActive('our') ? 'active' : '']">
          <router-link to="/our">关于我们</router-link>
        </li>
        <li :class="['item', isActive('join') ? 'active' : '']">
          <router-link to="/join">加入我们</router-link>
        </li>
      </ul>
    </span>
  </div>
</template>

<script>
export default {
  name: 'NavHeader',
  props: {
    mode: String
  },
  computed:{
    isHome(){
      return this.mode === 'home';
    },
  },
  methods: {
    isActive(type){
      return this.mode === type;
    }
  }
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 92px;
  line-height: 92px;
  display: flex;
  z-index: 100;
  .logo{
    margin: auto 0 auto 95px;
    img {
      width: 160px;
      height: 32px;
      vertical-align: middle;
    }
  }
  .space{
    flex: 1 0;
  }
  .nav{
    .menu{
      position: relative;
      display: inline-block;
      margin-right: 70px;
    }
    .item{
      float: left;
      width: 120px;
      font-size: 18px;
      line-height: 92px;
      cursor: pointer;
    }
    &.home{
      .item{
        color: #ffffff;
        &:hover{
          color: #81b7eb;
        }
        &.active{
          color: #81b7eb;
        }
      }
    }
    &.normal{
      .item{
        color: #000000;
        &:hover{
          color: #81b7eb;
        }
        &.active{
          color: #81b7eb;
        }
      }
    }
  }
}
</style>
