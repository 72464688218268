<template>
  <div class="join">
    <JoinHeader class="nav" :mode="mode"/>
    <section class="one" :style="{backgroundImage: 'url(' + (mode == 'society' ? banner : sbanner) + ')'}">
      <!-- <img v-if="mode == 'society'" src="@/assets/images/join-banner.png" alt="">
      <img v-else src="@/assets/images/join-s-banner.png" alt=""> -->
    </section>
    <section class="two">
      <div class="bg"></div>
      <div class="container">
        <div class="title" @click="handleTitle">
          <img v-if="mode == 'society'" src="@/assets/images/join-title.png" alt="">
          <img v-else src="@/assets/images/join-s-title.png" alt="">
        </div>
        <div class="scroll">
          <div class="content">
            <template v-for="item in list">
              <div v-if="item.home" class="item" @click="handleClick(item.id)" :key="item.id" :title="item.name">
                <div class="left">
                  <div class="name">{{item.name}}</div>
                  <div class="btn">查看岗位→</div>
                </div>
                <div class="icon">
                  <img v-if="item.icon" :src="item.icon" alt="">
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="qr">
          <div class="qr-bg"></div>
          <div class="qr-box">
            <img src="@/assets/images/join-qr-new.png" alt="">
          </div>
        </div>
        <div class="qr-txt">
          关注创乐汇微信公众号,获得最新招聘动态
        </div>
      </div>
    </section>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import JoinHeader from './JoinHeader.vue'
import Footer from './Footer.vue'
import { JOB_TYPE_ALL, JOB_BG_ONE } from "@/api"

import banner from '@/assets/images/join-banner.png'
import sbanner from '@/assets/images/join-s-banner1.png'

export default {
  name: 'Join',
  components: {
    JoinHeader,
    Footer
  },
  data(){
    return {
      mode: "society",
      list: [],
      limit: 6, //限制6个
      banner: banner,
      sbanner: sbanner
    };
  },
  computed:{
  },
  watch:{
    "$route"(){
      this.changeType();
    }
  },
  methods:{
    handleClick(id){
      this.$router.push("/job/"+this.mode+"/"+id);
    },
    handleTitle(){
      this.$router.push("/job/"+this.mode);
    },
    changeType(){
      this.mode = this.$route.params.type ? this.$route.params.type : "society";
      this.init();
    },
    init(){
      this.list = this.mode == "society" 
        ? this.$store.state.societyJobTypeList : this.$store.state.schoolJobTypeList;
      if(this.list.length > 0){
        return;
      }
      JOB_TYPE_ALL().then(res => {
        if(res.code != 1){
          return;
        }
        for (let i = 0; i < res.data.length; i++) {
          if(this.mode.toLowerCase() == res.data[i].group.toLowerCase()){
            this.list.push(res.data[i]);
            if(this.list.length >= this.limit){
              break;
            }
          }
        }
      });
    },
    queryBg(){
      JOB_BG_ONE().then(res => {
        if(res.code != 1){
          return;
        }
        this.banner = res.data && res.data.society ? res.data.society : this.banner;
        this.sbanner = res.data && res.data.school ? res.data.school : this.sbanner;
      });
    }
  },
  mounted(){
    this.queryBg();
    this.changeType();
  }
}
</script>
<style scoped lang="less">
.join{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.one{
  position: relative;
  line-height: 0;
  height: 598px;
  overflow: hidden;
  background: no-repeat center center;
  background-size: cover;
}

.two{
  position: relative;
  .bg{
    height: 760px;
    background: url("../assets/images/join-bg-2.png") no-repeat bottom center;
  }
  .container{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title{
    line-height: 0;
    margin-top: 94px;
  }
  .scroll{
    margin-top: 64px;
    width: 1316px;
    overflow: hidden;
  }
  .content{
    display: flex;
    justify-content: space-around;
    .item{
      width: 410px;
      height: 93px;
      background-color: #ffffff;
      position: relative;
      padding: 9px 18px;
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      box-shadow:0 0 6px 6px rgba(0, 0, 0, 0.04);
      cursor: pointer;
      margin: 10px 16px;
      min-width: 187px;
      .left{
        text-align: left;
        overflow: hidden;
        flex: 1;
        .name{
          font-size: 20px;
          line-height: 44px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .btn{
          font-size: 15px;
          line-height: 44px;
          color: #81b7eb;
        }
      }
      .icon{
        width: 26px;
        height: 26px;
        line-height: 0;
        img{
          width: 100%;
        }
      }
    }
  }
  .qr{
    position: relative;
    margin-top: 155px;
    margin-left: 13px;
    .qr-bg{
      width: 195px;
      height: 195px;
      background-color: #c8e6ff;
    }
    .qr-box{
      width: 195px;
      height: 195px;
      position: absolute;
      top: -12px;
      left: -13px;
      overflow: hidden;
    }
  }
  .qr-txt{
    margin-top: 27px;
    font-size: 14px;
    line-height: 21px;
    color: #838181;
  }
}

</style>