<template>
  <div class="job">
    <JoinHeader class="nav" :mode="mode"/>
    <section class="one">
      <div class="flex-box">
        <div class="container">
          <div class="left">
            <div class="top">
              <div class="title">
                <span class="style1" @click="handleHome">招聘首页</span>
                <span class="style2">→</span>
                职位列表
              </div>
              <div class="search-box">
                <div class="search-title">职位筛选</div>
                <div class="search-input">
                  <el-input placeholder="输入职位关键字" v-model="keywords" clearable @clear="handleSearch">
                    <el-button slot="append" class="input-btn" @click="handleSearch">搜索职位</el-button>
                  </el-input>
                </div>
                <div class="search-type">
                  <el-select v-model="typeId" placeholder="请选择" @change="handleSelect">
                    <el-option label="全部" value=""></el-option>
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id+''">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="middle">
              <el-table :data="list" style="width: 100%">
                <el-table-column
                  prop="title"
                  label="职位"
                  width="270">
                  </el-table-column>
                <el-table-column
                  prop="type"
                  label="职能类型"
                  :formatter="typeFormatter">
                </el-table-column>
                <el-table-column
                  label=""
                  width="170" align="right">
                  <template slot-scope="scope">
                    <el-button round @click="handleDetail(scope.row)">职位详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="bottom">
              <el-pagination layout="prev, pager, next" 
                :page-size="rows" 
                :total="total"
                prev-text="上一页"
                next-text="下一页"
                @current-change="handleCurrentChange"
                :hide-on-single-page="total <= rows"
                :background="false">
              </el-pagination>
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div class="title">
                <span>最新职位</span>
                <span class="more" @click="handleMore()">更多</span>
              </div>
              <template v-for="item in listTop">
                <div class="item" :key="item.id">
                  <div style="cursor: pointer;" @click="handleDetail(item)">
                    <span class="label">急</span>
                    <span class="text">{{item.title}}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import JoinHeader from './JoinHeader.vue'
import Footer from './Footer.vue'
import { JOB_TYPE_ALL, JOB_LIST, JOB_TOP } from "@/api"

export default {
  name: 'JoinList',
  components: {
    JoinHeader,
    Footer,
  },
  data(){
    return {
      mode: "society",
      options:[],
      page: 1,
      rows: 15,
      total: 0,
      typeId: "",
      keywords: "",
      list: [],
      listTop: [],
    };
  },
  watch:{
    "$route"(){
      this.changeRoute();
    }
  },
  methods:{
    typeFormatter(row){
      let name = row.type;
      for (let i = 0; i < this.options.length; i++) {
        if(this.options[i].id == row.type){
          name = this.options[i].name;
          break;
        }
      }
      return name;
    },
    handleHome(){
      let path = '/join/' + this.mode;
      this.$router.push(path); 
    },
    handleMore(){
      // let path = '/job/' + this.mode;
      // if(path != this.$route.path){
      //   this.$router.push(path);  
      // }
      this.page = 1;
      this.typeId = "";
      this.keywords = "";
      this.query();
    },
    handleDetail(row){
      this.$router.push('/detail/' + row.id);
    },
    handleSelect(){
      this.page = 1;
      this.query();
    },
    handleSearch(){
      this.page = 1;
      this.query();
    },
		handleCurrentChange(val) {
			this.page = val;
			this.query();
		},
    query(){
      let params = {
        page: this.page,
        rows: this.rows,
        type: this.typeId,
        keywords: this.keywords,
        mode: this.mode
      }
      JOB_LIST(params).then(res =>{
        if(res.code != 1){
          return;
        }
        this.list = res.data;
        this.total = res.total;
      });
    },
    handelType(){
      if(this.mode == "society"){
        this.options = this.$store.state.societyJobTypeList;
      }else{
        this.options = this.$store.state.schoolJobTypeList;
      }
      if(this.options.length > 0){
        return;
      }
      JOB_TYPE_ALL().then(res => {
        if(res.code != 1){
          return;
        }
        for (let i = 0; i < res.data.length; i++) {
          if(this.mode.toLowerCase() == res.data[i].group.toLowerCase()){
            this.options.push(res.data[i]);
          }
        }
      });
    },
    changeRoute(){
      this.mode = this.$route.params.type ? this.$route.params.type : "society";
      this.typeId = this.$route.params.id ? this.$route.params.id : "";
      this.handelType();
      this.query();
      this.queryTop();
    },
    queryTop(){
      let params = {
        mode: this.mode
      }
      JOB_TOP(params).then(res =>{
        if(res.code != 1){
          return;
        }
        this.listTop = res.data;
      });
    },
  },
  mounted(){
    this.changeRoute();
  }
}
</script>
<style scoped lang="less">
.job{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.one{
  position: relative;
  background: url("../assets/images/join-list-bg.png") no-repeat top center;
  background-size: cover;
  min-height: 723px;
  .flex-box{
    display: flex;
    justify-content: center;
  }
  .flex-box:before{
    content: '\0020';
    width: 110px;
  }
  .flex-box:after{
    content: '\0020';
    width: 110px;
  }
  .container{
    max-width: 1280px;
    position: relative;
    display: flex;
    justify-content: end;
    .left{
      width: 982px;
      flex: 1 0;
      text-align: left;
      .top{
        height: 204px;
        background-color: #ffffff;
        margin-top: 61px;
        position: relative;
        padding: 37px;
        .title{
          position: absolute;
          top: -29px;
          left: 0;
          font-size: 15px;
          line-height: 24px;
          .style1{
            color: #81b7eb;
            cursor: pointer;
          }
          .style2{
            color: #868686;
          }
        }
        .search-title{
          font-size: 20px;
          line-height: 20px;
          color: #181717;
        }
        .search-input{
          margin: 20px 0;
        }
        .input-btn{
          background-color: #81b7eb;
          border-radius: 0 5px 5px 0;
          border: none;
          color: #ffffff;
          height: 37px;
        }
      }
      .middle{
        background-color: #ffffff;
        margin-top: 37px;
        padding: 37px;
        .action{
          width: 106px;
          height: 33px;
        }
      }
      .bottom{
        margin-top: 29px;
        margin-bottom: 110px;
        text-align: center;
        color: #959595;
      }
    }
    .right{
      width: 279px;
      .box{
        margin-top: 61px;
        margin-left: 19px;
        margin-bottom: 100px;
        text-align: left;
        padding: 35px 0;
        background-color: #ffffff;
      }
      .title{
        margin: 0 24px;
        font-size: 20px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        .more{
          font-size: 15px;
          color: #949393;
          cursor: pointer;
        }
      }
      .item{
        margin: 0 24px;
        line-height: 1;
        padding: 23px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        .label{
          width: 16px;
          height: 16px;
          overflow: hidden;
          background-color: #eb2828;
          font-size: 12px;
          color: #ffffff;
          padding: 1px 2px;
        }
        .text{
          margin-left: 8px;
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

</style>