<template>
  <div class="our">
    <NavHeader class="nav" :mode="mode"/>
    <section class="one">
      <div class="container">
        <div class="left">
          <div class="bg">
            <img src="@/assets/images/our-bg-1.png" alt="">
          </div>
        </div>
        <!-- <div class="right" v-if="bannerList && bannerList.length > 0">
          <div class="bg-pic">
            <img :src="banner.pic" alt="" width="100%" height="100%">
          </div>
          <div class="mark">
            <div class="pic-box">
              <img :src="banner.pic" alt="" width="100%" height="100%">
            </div>
            <div class="btn-box">
              <div>
                <div class="pic-title">
                  {{ banner.title }}
                </div>
                <div class="pic-desc">
                  {{ banner.subtitle }}
                </div>
              </div>
              <div class="pic-btn">
                <div class="prev" @click="handlePrev"><img src="@/assets/images/our-pic-prev.png" alt=""></div>
                <div class="next" @click="handleNext"><img src="@/assets/images/our-pic-next.png" alt=""></div>
                <div class="num">
                  {{ indexStr }}
                  <span>/</span>
                  {{ totalStr }}
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="right">
          <el-carousel ref="carousel" indicator-position="none" 
            :autoplay="true" 
            height="935px"
            arrow="never">
            <el-carousel-item v-for="item in bannerList" :key="item.id">
          <div class="bg-pic">
            <img :src="item.pic" alt="" width="100%" height="100%">
          </div>
          <div class="mark">
            <div class="pic-box">
              <img :src="item.pic" alt="" width="100%" height="100%">
            </div>
            <div class="btn-box">
              <div>
                <div class="pic-title">
                   {{ item.title }}
                </div>
                <div class="pic-desc">
                  {{ item.subtitle }}
                </div>
              </div>
              <div class="pic-btn">
                <div class="prev" @click="handlePrev"><img src="@/assets/images/our-pic-prev.png" alt=""></div>
                <div class="next" @click="handleNext"><img src="@/assets/images/our-pic-next.png" alt=""></div>
                <div class="num">
                  {{ item.indexStr }}
                  <span>/</span>
                  {{ totalStr }}
                </div>
              </div>
            </div>
          </div>
              
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="bg-txt">
        <img src="@/assets/images/our-daily-life.png" alt="">
      </div>
      <div class="box">
            <div class="title">
              <img src="@/assets/images/our-title-1.png" alt="">
            </div>
            <div class="desc">
              <p>我们提倡努力工作，好好生活。</p>
              <p>所以我们不仅有丰富的下午茶、有各类节日主题活动及有趣的生日会、</p>
              <p>还有愉悦身心的团建，带领小伙伴们游山历水，尝尽美食。 </p>
              <p>生命在于运动，我们积极鼓励小伙伴们成立各类运动社团，</p>
              <p>并设立个人奖、团队奖给予激励。</p>
              <p>我们希望每个人都有好的状态，拼时不负青春，闲时不负自己。</p>
            </div>
            <div class="divide"></div>
          </div>
    </section>
    <section class="two">
      <div class="bg">
        <img src="@/assets/images/our-bg-2.png" alt="">
      </div>
      <div class="container">
        <div class="left">
          <div class="title">
            <img src="@/assets/images/our-title-2.png" alt="">
          </div>
          <div class="desc">
            <p>创乐汇成立于2016年10月，以科学精神快速进行技术积累，</p>
            <p>在研发壁垒极高的实操足球游戏领域实现技术追赶已经成为国内领先的运动竞技游戏开发商。</p>
            <p><br/></p>
            <p><span>→关于项目</span>：公司的首款竞技实操手游《绿茵之巅》已在全球发行，由前巴西巨星卡洛斯代言，</p>
            <p>上线一年在全球即拥有500多万用户。</p>
            <p><br/></p>
            <p><span>→关于团队</span>：公司主创来自知名游戏团队，曾主导和参与过多款成功项目的开发和运营。</p>
            <p>公司90%以上为本科及以上学历人才，团队小而精，用智慧创造游戏。</p>
            <p><br/></p>
            <p><span>→关于研发氛围</span>：公司内的需求发起与研发过程，讲究科学精神。所有的讨论均依赖客观的实验与数据展开，勇于</p>
            <p>挑战研发中遇到得各种困难实现快速积累。研发过程中的实验与设计积累了大量有价值的动作、渲染、AI与系统</p>
            <p>设计技术文档，为后续加入团队的小伙伴提供了飞速成长的条件。</p>
            <p><br/></p>
            <p>未来，公司将在目前产品线的基础上，不断扩大研发规模和范围，最终制作涵盖多个体育项目门类的竞技游戏。</p>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/images/our-pic-2.png" alt="">
        </div>
      </div>
    </section>
    <section class="three">
      <div class="container">
        <div class="title">
          <img src="@/assets/images/our-title-3.png" alt="">
        </div>
        <div class="box">
          <ul>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-1.png" alt="">
              </span>
              <span class="txt">舒适的办公环境 及硬件设备</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-2.png" alt="">
              </span>
              <span class="txt">奖金</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-3.png" alt="">
              </span>
              <span class="txt">六险一金</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-4.png" alt="">
              </span>
              <span class="txt">补充医疗险</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-5.png" alt="">
              </span>
              <span class="txt">餐饮补贴</span>
            </li>
          </ul>
          <ul>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-6.png" alt="">
              </span>
              <span class="txt">节日礼品礼金</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-7.png" alt="">
              </span>
              <span class="txt">团建活动</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-8.png" alt="" style="margin-left: 16px;">
              </span>
              <span class="txt">下午茶</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-9.png" alt="">
              </span>
              <span class="txt">生日关怀</span>
            </li>
            <li>
              <span class="icon">
                <img src="@/assets/images/our-icon-10.png" alt="">
              </span>
              <span class="txt">年度体检</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="four">
      <div class="bg">
        <img src="@/assets/images/our-bg-4.png" alt="">
      </div>
      <div class="container">
        <div class="title">
          <img src="@/assets/images/our-title-4.png" alt="">
        </div>
        <div class="box">
          <template v-for="item in qaList">
            <div class="item" :key="item.id">
              <div class="icon">
                <img :src="item.head" alt="">
              </div>
              <div class="name">
                {{item.title}}
              </div>
              <div class="desc">
                {{item.subtitle}}
              </div>
              <div class="btn">
                <router-link :to="'/qa/'+item.id">
                  <img src="@/assets/images/our-more.png" alt="">
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import NavHeader from './NavHeader.vue'
import Footer from './Footer.vue'
import { BANNER_LIST, QA_LIST } from "@/api"

export default {
  name: 'Our',
  components: {
    NavHeader,
    Footer
  },
  data(){
    return {
      mode: "our",
      bannerList: [],
      qaList: [],
      total: 0,
    };
  },
  computed: {
    totalStr(){
      return this.total < 10 ? '0'+this.total : this.total;
    }
  },
  methods:{
    queryBannerList(){
      this.bannerList = this.$store.state.bannerList;
      if(this.bannerList.length > 0){
        this.total = this.bannerList.length;
        return;
      }
      BANNER_LIST().then(res => {
        if(res.code != 1){
          return;
        }
        for (let i = 0; i < res.data.length; i++) {
          let node = res.data[i];
          node.indexStr = i+1 < 10 ? '0'+(i+1) : i+1;
          this.bannerList.push(node);
        }
        this.total = this.bannerList.length;
      });

    },
    queryQaList(){
      this.qaList = this.$store.state.qaList;
      if(this.qaList.length > 0){
        return;
      }
      QA_LIST().then(res => {
        if(res.code != 1){
          return;
        }
        for (let i = 0; i < res.data.length; i++) {
          this.qaList.push(res.data[i]);
        }
      });
    },
    handlePrev(){
      this.$refs.carousel.prev();
    },
    handleNext(){
      this.$refs.carousel.next();
    },
  },
  mounted(){
    this.queryBannerList();
    this.queryQaList();
  },
  beforeDestroy() {

  }
  
}
</script>
<style scoped lang="less">
.our{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.one{
  position: relative;
  height: 935px;
  line-height: 0;
  overflow: hidden;
  z-index: 1;
  .container{
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    .left{
      position: relative;
      overflow: hidden;
    }
    .right{
      position: relative;
      width: 1173px; // 61.1%;
      overflow: hidden;
      background-color: #f2f2f2;
      min-width: 1015px;
      flex: 1 0;
      height: 100%;
      .bg-pic{
        height: 100%;
        filter: blur(52.4px);
      }
      .mark{
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .pic-box{
        width: 975px;
        height: 650px;
        overflow: hidden;
      }
      .btn-box{
        margin-top: 46px;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        text-align: left;
      }
      .pic-title{
        font-size: 18px;    
        line-height: 29px;
      }
      .pic-desc{
        font-size: 14px;
        line-height: 42px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 500px;
      }
      .pic-btn{
        display: flex;
        justify-content: space-between;
        .prev{
          padding-top: 30px;
          cursor: pointer;
        }
        .next{
          padding-top: 30px;
          margin: 0 38px;
          cursor: pointer;
        }
        .num{
          font-size: 42px;
          span{
            margin: 0 15px;
          }
        }
      }
    }
  }

  .bg-txt{
    position: absolute;
    top: -6px;
    left: 7px;
    z-index: 10;
  }
  
  .box{
    position: absolute;
    left: 209px;
    top: 389px;
    text-align: left;
    color: #ffffff;
    .desc{
      margin-top: 34px;
      font-size: 14px;
      line-height: 30px;
      white-space: pre-line;
    }
    .divide{
      margin-top: 73px;
      width: 57px;
      height: 1px;
      background-color: #ffffff;
    }
  }
}

.two{
  margin-top: -5px;
  position: relative;
  overflow: hidden;
  .container{
    position: absolute;
    top: 118px;
    left: 209px;
    right: 209px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    .desc{
      color: #000000;
      margin-top: 45px;
      white-space: pre-line;
      line-height: 30px;
      span{
        color: #45a0ea;
      }
    }
  }
}

.three{
  position: relative;
  background-color: #f5f5f5;
  z-index: 1;
  height: 596px;
  .container{
    position: absolute;
    top: 118px;
    left: 209px;
    right: 209px;
    text-align: left;
    .box{
      margin-top: 65px;
      ul{
        display: flex;
        justify-content: space-between;
        margin-bottom: 43px;
        li{
          text-align: center;
          width: 110px;
          font-size: 15px;
          line-height: 22px;
          span{
            display: block;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.four{
  position: relative;
  overflow: hidden;
  .container{
    position: absolute;
    top: 122px;
    left: 209px;
    right: 209px;
    text-align: left;
    .box{
      display: flex;
      justify-content: space-around;
      margin-top: 38px;
    }
    .item{
      text-align: center;
      .icon{
        width: 222px;
        height: 222px;
        background-color: #45a0ea;
        border-radius: 222px;
        margin: 37px auto;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .name{
        font-size: 22px;
        line-height: 42px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desc{
        font-size: 14px;
        line-height: 23px;
        margin-top: 23px;
        white-space: pre-line;
        overflow: hidden;
        max-height: 92px;
      }
      .btn{
        margin-top: 38px;
        cursor: pointer;
      }
    }
  }
}

</style>