import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Game from '../views/Game.vue'
import Our from '../views/Our.vue'
import Qa from '../views/Qa.vue'
import Join from '../views/Join.vue'
import JobList from '../views/JobList.vue'
import JobDetail from '../views/JobDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/game',
    name: 'Game',
    component: Game
  },
  {
    path: '/our',
    name: 'Our',
    component: Our
  },
  {
    path: '/qa/:index',
    name: 'Qa',
    component: Qa
  },
  {
    path: '/join',
    name: 'Join',
    component: Join
  },
  {
    path: '/join/:type',
    name: 'Join By Type',
    component: Join
  },
  {
    path: '/job/:type/:id',
    name: 'JobList',
    component: JobList
  },
  {
    path: '/job/:type',
    name: 'JobList',
    component: JobList
  },
  {
    path: '/detail/:id',
    name: 'JobDetail',
    component: JobDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo(0,0);
});

export default router
