<template>
  <div class="footer">
    <div class="left">
      <div class="nav">
        <ul class="menu">
          <li class="item"><router-link to="/">首页</router-link></li>
          <li class="item"><router-link to="/game">游戏产品</router-link></li>
          <li class="item"><router-link to="/our">关于我们</router-link></li>
          <li class="item"><router-link to="/join">加入我们</router-link></li>
        </ul>
      </div>
      <p>
        <span>版权所有：创乐汇互动科技有限责任公司</span>
        <span style="margin-left:46px;">
          <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2022007690号-1</a>
        </span>
      </p>
      <p>
        Copyright:JOY NOVA Technology Co.,Ltd.All Rights Reserved
      </p>
      <p>
        本公司游戏产品适合18岁以上成年人使用（未成年家长监护)
      </p>
      <p>
        健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 沉迷游戏伤身 合理安排时间 享受健康生活
      </p>
    </div>
    <div class="space"></div>
    <div class="right">
      <div class="logo home">
        <img src="@/assets/images/logo-footer.png" />
      </div>
      <div class="phone">
        联系方式：028-65220625
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  min-height: 264px;
  background-color: #6cb8f8;
  display: flex;
  color: #ffffff;
  .space{
    flex: 1 0;
  }
  .left{
    margin-left: 11.04%; // 212px
    text-align: left;
    padding-top: 61px;
    font-size: 15px;
    .nav{
      .menu{
        display: inline-block;
      }
      .item{
        float: left;
        width: 120px;
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;
      }
    }
    p {
      margin: 10px 0;
      color: #949494;
    }
  }
  .right{
    margin-right: 10.88%; // 209px
    padding-top: 48px;
    .logo{
      // text-align: right;
      display: flex;
      justify-content: end;
    }
    .phone{
      margin-top: 46px;
      font-size: 26px;
      width: 349px;
      height: 57px;
      line-height: 57px;
      border: 1px solid #ffffff;
    }
  }
}

</style>
