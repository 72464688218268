<template>
  <div class="game">
    <NavHeader class="nav" :mode="mode"/>
    <div class="content">
      <div class="bg">
        <div class="bg-left">
          <img src="@/assets/images/game-left-bg.png" alt="">
        </div>
        <div class="bg-middle">
          <img src="@/assets/images/game-title-bg.png" alt="">
        </div>
        <div class="bg-right">
          <img src="@/assets/images/game-bg.png" alt="">
        </div>
      </div>
      <!-- <div class="big-bg"></div> -->
      <div class="text">
        <p>《绿茵之巅》是一款实时对战的操作类的足球游戏，拥有Fifpro的真实球员授权，由网易在全球范围内代理运营。</p>
        <p>游戏自18年上线以来，在中国、东南亚、南美等世界各地服务了近千万足球爱好者。游戏由世界巨星罗伯特卡洛斯代言，游戏内也真实还原其球场上的风采。真实球员的授权、巨星代言、跨平台的流畅联网作战，都是《绿茵之巅》努力为全球足球爱好者带来的极致体验。</p>
        <p><br/></p>
        <p class="title">→FIFPRO：所有职业足球运动员的全球代表性组织 </p>
        <p>国际职业足球运动员联合会（简称FIFPro），是一个代表全球各地职业足球运动员的国际体育组织，现共有65个国家的职业球员,总计有6万多名男性和女性足球运动员。在游戏内比赛中使用图像和足球运动员的名字是在FIFPro Commercial Enterprises BV的许可下进行的</p>
        <p><br/></p>
        <p class="title">→策略游戏，灵活变阵</p>
        <p>本游戏以足球体育竞技为主题，通过球星效应鼓励玩家热爱体育，有助于培养玩家对足球竞技的兴趣和理解。玩家之间可以互相切磋交流，能够带给玩家积极愉悦的情绪体验。游戏玩法具有一定的竞技性，有助于锻炼玩家的反应能力和观察能力，弘扬奥林匹克精神。</p>
      </div>
      <div class="person">
        <img src="@/assets/images/person.png" alt="">
      </div>
      <div class="btn-container">
        <div><a :href="game.site" target="_bank"><img src="@/assets/images/btn-game.png" alt=""></a></div>
        <div><a :href="game.video" target="_bank"><img src="@/assets/images/btn-play.png" alt=""></a></div>
      </div>
    </div>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import NavHeader from './NavHeader.vue'
import Footer from './Footer.vue'
import { GAME_ONE } from "@/api"

export default {
  name: 'Game',
  components: {
    NavHeader,
    Footer
  },
  data(){
    return {
      mode: "game",
      game: {},
    };
  },
  methods:{
    query(){
      GAME_ONE().then(res => {
        if(res.code != 1){
          return;
        }
        this.game = res.data;
      });
    }
  },
  mounted(){
    this.query();
  }
}
</script>
<style scoped lang="less">
.game{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.nav{
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(0,0,0,0.08);
}

.content{
  margin-top: 92px;
  height: 1009px;
  position: relative;
  overflow: hidden;
}

.bg{
  display: flex;
  position: relative;
  .bg-left{
    position: relative;
  }
  .bg-middle{
    z-index: 1;
    margin-left: 65px;
    margin-top: 84px;
  }
  .bg-right{
    position: absolute;
    top: -88px;
    right: 0;
  }
}
// .big-bg{
//     background: url("../assets/images/game-big-bg.png") no-repeat top center;
//     /* background-size: cover; */
//     position: relative;
// }

.person{
  position: absolute;
  top: -66px;
  right: 0;
  z-index: 1;
}

.text{
  position: absolute;
  top: 429px;
  left: 247px;
  text-align: left;
  width: 890px;
  z-index: 10;
  line-height: 30px;
  white-space: pre-line;
  .title{
    color: #45a0ea;
    font-size: 16px;
  }
}

.btn-container{
  position: absolute;
  top: 845px;
  left: 250px;
  width: 379px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
}


</style>