<template>
  <div class="job">
    <JoinHeader class="nav" :mode="mode"/>
    <section class="one">
      <div class="flex-box">
        <div class="container">
          <div class="left">
            <div class="top">
              <div class="title" @click="handleMore()">
                返回职位列表 >
              </div>
              <div class="content">
                <div class="head">
                  <span class="head-title">{{job.title}}</span>
                  <span class="head-time">发布时间：{{job.date ? job.date.split(" ")[0] : ''}}</span>
                </div>
                <div v-if="jobExt.jobContent" class="item">
                  <div class="item-title">工作内容</div>
                  <div class="item-txt" v-html="jobExt.jobContent"></div>
                </div>
                <div v-if="jobExt.jobSkill" class="item">
                  <div class="item-title">任职要求</div>
                  <div class="item-txt" v-html="jobExt.jobSkill"></div>
                </div>
                <div v-if="jobExt.jobExperience" class="item">
                  <div class="item-title">工作经验</div>
                  <div class="item-txt" v-html="jobExt.jobExperience"></div>
                </div>
                <div v-if="jobExt.jobDegree" class="item">
                  <div class="item-title">学历要求</div>
                  <div class="item-txt" v-html="jobExt.jobDegree"></div>
                </div>
                <div v-if="jobExt.jobEmail" class="item">
                  <div class="item-title">简历投递</div>
                  <div class="item-txt" v-html="jobExt.jobEmail"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div class="title">
                <span>最新职位</span>
                <span class="more" @click="handleMore()">更多</span>
              </div>
              <template v-for="item in listTop">
                <div class="item" :key="item.id">
                  <div style="cursor: pointer;" @click="handleDetail(item)">
                    <span class="label">急</span>
                    <span class="text">{{item.title}}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer class="footer"></Footer>
  </div>
</template>

<script>

import JoinHeader from './JoinHeader.vue'
import Footer from './Footer.vue'
import { JOB_DETAIL, JOB_TOP } from "@/api"

export default {
  name: 'JoinList',
  components: {
    JoinHeader,
    Footer,
  },
  data(){
    return {
      mode: "society",
      id: "",
      job: {},
      listTop: [],
      jobExt: {
				jobContent: "", //内容
				jobSkill: "", //技能
				jobExperience: "", //经验
				jobDegree: "", //学历
				jobEmail:"" //投递
			},
    };
  },
  watch:{
    "$route"(){
      this.changeRoute();
    }
  },
  methods:{
    async query() {
      let params = {
        id: this.id,
      }
      let res = await JOB_DETAIL(params);
      if(res.code != 1){
        this.$router.push("/job/society"); //跳转列表
        return;
      }
      this.job = res.data.job;
      this.jobExt = this.job.content ? JSON.parse(this.job.content) : {};
      let type = res.data.type;
      this.mode = type.group.toLowerCase();
      this.queryTop();
    },
    changeRoute(){
      this.id = this.$route.params.id ? this.$route.params.id : "";
      this.query();
    },
    queryTop(){
      let params = {
        mode: this.mode
      }
      JOB_TOP(params).then(res =>{
        if(res.code != 1){
          return;
        }
        this.listTop = res.data;
      });
    },
    handleMore(){
      let path = '/job/' + this.mode;
      this.$router.push(path); 
    },
    handleDetail(row){
      let path = '/detail/' + row.id;
      if(path != this.$route.path){
        this.$router.push(path);
      }
    },
  },
  mounted(){
    this.changeRoute();
  }
}
</script>
<style scoped lang="less">
.job{
  max-width: 1920px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.one{
  position: relative;
  background: url("../assets/images/join-list-bg.png") no-repeat top center;
  background-size: cover;
  min-height: 723px;
  .flex-box{
    display: flex;
    justify-content: center;
  }
  .flex-box:before{
    content: '\0020';
    width: 110px;
  }
  .flex-box:after{
    content: '\0020';
    width: 110px;
  }
  .container{
    max-width: 1280px;
    position: relative;
    display: flex;
    justify-content: end;
    .left{
      width: 982px;
      flex: 1 0;
      text-align: left;
      .top{
        background-color: #ffffff;
        margin-top: 61px;
        margin-bottom: 101px;
        position: relative;
        padding: 37px;
        .title{
          position: absolute;
          top: -29px;
          left: 0;
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
        }
        .head{
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
          .head-title{
            font-size: 30px;
            line-height: 32px;
            color: #181717;
            font-weight: 700;
          }
          .head-time{
            font-size: 15px;
            line-height: 32px;
            color: #828282;
          }
        }
        .item{
          font-size: 15px;
          line-height: 32px;
          padding: 24px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
          white-space: pre-line;
          .item-title{
            font-weight: 700;
          }
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
    .right{
      width: 279px;
      .box{
        margin-top: 61px;
        margin-left: 19px;
        margin-bottom: 100px;
        text-align: left;
        padding: 35px 0;
        background-color: #ffffff;
      }
      .title{
        margin: 0 24px;
        font-size: 20px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        .more{
          font-size: 15px;
          color: #949393;
          cursor: pointer;
        }
      }
      .item{
        margin: 0 24px;
        line-height: 1;
        padding: 23px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        .label{
          width: 16px;
          height: 16px;
          overflow: hidden;
          background-color: #eb2828;
          font-size: 12px;
          color: #ffffff;
          padding: 1px 2px;
        }
        .text{
          margin-left: 8px;
          font-size: 15px;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
  }
}

</style>