<template>
  <div class="header">
    <span class="logo">
      <img src="@/assets/images/logo-normal.png" />
    </span>
    <span class="nav">
      <ul class="menu">
        <li :class="['item', isActive('society') ? 'active' : '']">
          <router-link to="/join">社会招聘</router-link>
        </li>
        <li :class="['item', isActive('school') ? 'active' : '']">
          <router-link to="/join/school">校园招聘</router-link>
        </li>
      </ul>
    </span>
    <span class="space"></span>
    <span class="back">
      <router-link to="/">返回官网</router-link>
    </span>
  </div>
</template>

<script>
export default {
  name: 'JoinHeader',
  props: {
    mode: String
  },
  computed:{
    
  },
  methods: {
    isActive(type){
      return this.mode === type;
    }
  }
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 92px;
  line-height: 92px;
  display: flex;
  z-index: 100;
  
  .logo{
    margin: auto 0 auto 95px;
    img {
      width: 160px;
      height: 32px;
      vertical-align: middle;
    }
  }

  .space{
    flex: 1 0;
  }

  .nav{
    margin-left: 195px;
    vertical-align: middle;
    .menu{
      position: relative;
      display: inline-block;
      vertical-align: middle;
    }
    .item{
      float: left;
      width: 120px;
      font-size: 18px;
      line-height: 92px;
      cursor: pointer;
      &:hover{
        color: #81b7eb;
      }
      &.active{
        color: #81b7eb;
      }
    }
  }

  .back{
    width: 108px;
    height: 31px;
    margin: auto 94px;
    background-color: rgb(129,183,235, 0.84);
    border-radius: 15.5px;
    line-height: 31px;
    color: #ffffff;

    a{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
