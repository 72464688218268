import qs from 'qs'
import axios from 'axios'

let axiosQuery = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

// 请求拦截器
axiosQuery.interceptors.request.use(
    config => {
      config.data = qs.stringify(config.data) // 转为formdata数据格式
      return config
    },
    error => Promise.error(error)
);

let handleRes = (res) => {
    if(res.status === 200){
        return Promise.resolve(res.data);
    }else{
        return Promise.reject(res);
    }
}

//job bg
export const JOB_BG_ONE = (params) => {
    return axiosQuery.post(`/api/jobBg/one`, params).then(handleRes);
}

//job type
export const JOB_TYPE_ALL = (params) => {
    return axiosQuery.post(`/api/jobType/all`, params).then(handleRes);
}

//job
export const JOB_LIST = (params) => {
    return axiosQuery.post(`/api/job/page`, params).then(handleRes);
}
export const JOB_TOP = (params) => {
    return axiosQuery.post(`/api/job/top`, params).then(handleRes);
}
export const JOB_DETAIL = (params) => {
    return axiosQuery.post(`/api/job/detail`, params).then(handleRes);
}

//Qa
export const QA_LIST = (params) => {
    return axiosQuery.post(`/api/qa/list`, params).then(handleRes);
}

//Banner
export const BANNER_LIST = (params) => {
    return axiosQuery.post(`/api/banner/list`, params).then(handleRes);
}

//Game
export const GAME_ONE = (params) => {
    return axiosQuery.post(`/api/game/one`, params).then(handleRes);
}
